import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/20/solid";
import { StarIcon } from "@heroicons/react/24/outline";
import { Link, MetaFunction } from "@remix-run/react";
import { Footer } from "~/components/marketing/footer";
import ScrollProgressBar from "~/components/ScrollProgress";
import { appDetails } from "~/utils/misc";
import culture from "/img/culture.png";
import hero from "/img/digital-brain.jpg";
import img from "/img/future-disruptions.jpg";
import futureDrivers from "/img/future_drivers.png";
import innovation from "/img/innovation.png";
import leadership from "/img/leadership.png";
import logo from "/img/logo.svg";
import mindset from "/img/mindset.png";
import organisation from "/img/organisation.png";
import planning from "/img/planning.png";
import risk from "/img/risk.png";
import strategy from "/img/strategy.png";

export const meta: MetaFunction = () => {
  return [
    { title: appDetails.title },
    { name: "description", content: appDetails.description },
  ];
};

function CTA() {
  return (
    <div className="bg-gray-100 my-2">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-24 lg:flex lg:items-center lg:justify-between lg:px-8">
        <h2 className="max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          Ready for the future? <br />
          Complete the survey today and get your readiness score
        </h2>
        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
          <Link
            to="#"
            className="rounded-md bg-accent px-3.5 py-2.5 text-3xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Get started
          </Link>
        </div>
      </div>
    </div>
  );
}

function HeaderNav() {
  return (
    <div className="flex flex-wrap gap-2 justify-between items-center px-2 min-h-[50px]">
      <div>
        {/* <Bars3Icon className="text-primary h-8 w-8" /> */}
        <Link to="/" className="flex flex-wrap items-center gap-4">
          <img src={logo} className="h-10 w-10" />
        </Link>
      </div>
      <div className="">
        <Link to="/" className="flex flex-wrap items-center gap-4 text-primary">
          <p className="font-bold">APTIM-Solutions</p>
          <p>Disruptive Futures Platform</p>
          <p>Future Disruptions</p>
        </Link>
        <p className="font-light text-xs text-primary">
          advancing performance through input management
        </p>

        {/* <Link to="/" className="consulting-header-link">
          Home
        </Link>
        <Link to="/insights" className="consulting-header-link">
          Insights
        </Link>
        <Link to="/case-studies" className="consulting-header-link">
          Case Studies
        </Link> */}
      </div>
      <div>
        <Link to="login">
          <ArrowRightStartOnRectangleIcon className="h-6 w-6 text-primary" />
        </Link>
      </div>
    </div>
  );
}

function Hero() {
  return (
    <div
      className="mx-auto h-[80vh] overflow-y-scroll bg-cover bg-fixed bg-center bg-no-repeat shadow-lg"
      style={{
        backgroundImage: `url(${img})`,
      }}
    >
      <div className="mt-96">
        <div className="p-4 sm:p-8 max-w-2xl bg-primary ml-2 rounded-lg text-primary-foreground">
          <div className="font-inter text-4xl font-extrabold tracking-tight">
            Future Disruptions
          </div>
          <div className="font-inter text-3xl tracking-tight font-light">
            Measure Your Readiness for Disruption Today
          </div>
          <div className="mt-1 text-sm font-medium text-slate-300">
            2024 | Disruptive Readiness Index Survey
          </div>
        </div>
      </div>
    </div>
  );
}

function Category({
  title,
  description,
  caseStudy,
  imgSrc,
  keyCharacteristics,
}: {
  title: string;
  description: string;
  caseStudy: string;
  imgSrc;
  keyCharacteristics?: string[];
}) {
  return (
    <div className="overflow-hidden bg-white py-4 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="mt-2 text-pretty text-4xl font-bold tracking-tight text-secondary sm:text-5xl">
                {title}
              </h2>
              <p className="mt-6 text-lg/8 text-gray-600">{description}</p>
              <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <StarIcon className="absolute left-1 top-1 size-5 text-indigo-600" />
                  </dt>{" "}
                  <dd className="inline">{caseStudy}</dd>
                </div>
                {(keyCharacteristics ?? []).length > 0 ? (
                  <div className="flex gap-2 flex-col">
                    <div>
                      <p className="font-bold">Key Characteristics</p>
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {keyCharacteristics?.map((feature, idx) => (
                        <p
                          key={idx}
                          className="font-semibold my-2 bg-primary text-white px-1 rounded-md"
                        >
                          {feature}
                        </p>
                      ))}
                    </div>
                  </div>
                ) : null}
              </dl>
            </div>
          </div>
          <img
            alt={title}
            src={imgSrc}
            className="hidden sm:block h-[500px] w-auto max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0 object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default function ConsultingLandingPage() {
  return (
    <div className="bg-white min-h-[100vh]">
      <HeaderNav />
      <ScrollProgressBar />
      <Hero />
      <div className="relative bg-white">
        <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
          <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
            <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
              <img
                alt="Future Disruptions"
                src={hero}
                className="absolute inset-0 size-full bg-gray-50 object-cover object-left-top"
              />
            </div>
          </div>
          <div className="px-6 lg:contents">
            <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
              <p className="text-base/7 font-semibold text-primary">
                APTIM-Solutions
              </p>
              <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                Disruptive Readiness Index
              </h1>
              <p className="mt-6 text-xl/8 text-gray-700">
                In a world where the speed of change, opportunity and left-field
                competitive threats are occurring at an ever-increasing pace,
                there haven’t been many periods in history where disruption has
                held such an important role in business success.
              </p>
              <div className="mt-10 max-w-xl text-base/7 text-gray-700 lg:max-w-none">
                <p>
                  The Annual Disruptive Readiness Index aims to benchmark just
                  how businesses are responding – providing a comprehensive
                  insight into the organisational acceptance, preparedness and
                  execution of strategies to address the future tectonic shifts
                  that face both public and private institutions.
                </p>
                <p>
                  As an industry leader, we would value your input into this
                  year's report. It will take ten to fifteen minutes of your
                  time and we will share a personal Readiness Score with you,
                  along with the full report, when the results are released.
                  Please feel free to share the questionnaire with your teams,
                  for an organisational score.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CTA />
      <Category
        title="A Disruptive Mindset"
        description="Acting with a disruptive mindset enables organisations to compete successfully for a share of the future opportunity, as well as winning in today’s world."
        // caseStudy="Elon Musk personifies this mindset: to reach his end goal to travel to and colonise Mars requires power storage, solar power, reusable rockets, electric transportation and hyperloop travel. Today, he is disrupting any number of industries to this end, as he will tomorrow."
        caseStudy="Ada Lovelace personifies this mindset: she wrote what is regarded as the first computer algorithm. She saw, what was then a complex calculator, as something that could be used for more than just numbers. She saw the potential for it to be used for any form of symbolic processing, including music and art. She was the first to see the potential of a computer as a general-purpose machine."
        imgSrc={mindset}
        keyCharacteristics={[
          "Challenge the status quo",
          "Innovative thinking",
          "Embracing Risk",
          "Future-focused",
          "Adaptable",
          "Collaborative",
        ]}
      />
      <Category
        title="Innovation"
        description="Innovation is the lifeblood of disruption."
        caseStudy="Think of Amazon, the world’s largest retailer – who owns no stores; Airbnb, the world’s largest provider of rooms – who owns no hotels; or Uber, the world’s largest taxi company – who owns no taxis. Then ask yourself where they were 20 years ago."
        imgSrc={innovation}
        keyCharacteristics={[
          "Creativity",
          "Problem-solving focus",
          "Value creation",
          "Practical application",
          "Risk taking",
          "Continuous improvement",
        ]}
      />
      <Category
        title="Strategy"
        description="A good strategy is the identification of an emerging disruptive opportunity and/or threat, and the formulation of an innovative solution."
        caseStudy="The founder of Softbank, Masayoshi Son, is often held to be an exemplar of long-term thinking and planning. Through intense research he imagines what the future may look like in 30 years and then works back, asking himself what infrastructure, technologies, architecture, and business models need to be in place to deliver this. He then invests in the opportunities that fit within this disruptive framework."
        imgSrc={strategy}
        keyCharacteristics={[
          "Clear objective",
          "Understanding of the problem",
          "Prioritisation",
          "Actionable",
          "Competitive advantage",
          "Adaptability",
        ]}
      />
      <Category
        title="Culture"
        description="A positive and purposeful culture is essential for growth."
        caseStudy="Culture is the key operating system for any disruptive business. Netflix not only disrupted their business model to create a streaming service but disrupted the entire entertainment market. Central to this was how they managed and empowered their people. They embedded a core set of behaviours and then gave their employees the freedom and responsibility to practice them."
        imgSrc={culture}
        keyCharacteristics={[
          "Share vision and purpose",
          "Core values",
          "Inclusive",
          "Open communication",
          "Empowerment",
          "Development",
        ]}
      />
      <Category
        title="Planning"
        description="Planning does not guarantee success, but it certainly can tip the odds in your favour. It provides a baseline from which you can seize adjacent opportunities as they present themselves."
        caseStudy="Consider the likes of Uber, as they start to enter other markets such as Uber Eats; and Amazon, who have morphed from an online bookstore to one of the largest fulfilment and delivery companies in the world."
        imgSrc={planning}
        keyCharacteristics={[
          "Goal oriented",
          "Forward looking",
          "Decision oriented",
          "Flexibility",
          "Comprehensive",
          "Resource allocation",
        ]}
      />
      <Category
        title="Leadership"
        description="Leadership is not about responding to disruption, it’s about redefining disruption."
        caseStudy="Take Microsoft, who saw the future in software when everyone else was focused on hardware and then pivoted again into cloud computing. Then there is Intel who saw the importance of pivoting away from the business on which they were founded, into microprocessors."
        imgSrc={leadership}
        keyCharacteristics={[
          "Visionary thinking",
          "Embracing change",
          "Risk taking",
          "Foster innovation",
          "Resilience",
          "Customer centric",
        ]}
      />
      <Category
        title="Risk Management"
        description="Risk and mitigation are key components of any disruptive strategy. Every organisation must recognise one simple fact: every product, service and operating model will be disrupted – it’s only a matter of time."
        caseStudy="Risk management helps deconstruct current models and identify new options for the future, providing a more evolutionary path towards revolution and re-invention. Consider Toyota, who created a mass market for hybrid electric vehicles without abandoning its core business and has since accelerated its plan for all-electric vehicles by five years."
        imgSrc={risk}
        keyCharacteristics={[
          "Positive approach",
          "Structured",
          "Identification",
          "Risk analysis",
          "Prioritisation",
          "Mitigation",
        ]}
      />
      <Category
        title="Organisation"
        description="Organisational processes, architecture, infrastructure and capabilities that are well suited to today’s challenges will most probably buckle under the strain of future demands."
        caseStudy="Likewise, key employees may lack the skills needed to cope with the additional complexity that disruptive growth brings. Before you embark on a game-changing initiative, your organisation needs to be prepared for it."
        imgSrc={organisation}
        keyCharacteristics={[
          "Innovation driven",
          "Customer centric",
          "Agile",
          "Bold decision making",
          "Embrace technology",
          "Challenge the status quo",
        ]}
      />
      <Category
        title="Future Drivers"
        description="What are the key drivers for your organisation that will influence your future disrupted world?"
        caseStudy="Jean-Pascal Tricoire, Chairman and CEO of Schneider Electric, said “Schneider is more than 180 years old. We started in iron and steel and now we are digital solutions for energy, using automation. What we’ve learned is that there are two kinds of pivots: the pivots you initiate, which are good for the company, and the pivots the environment imposes on you. Those are very painful. So, our obsession in the past 20 years has been to anticipate, to choose our pivots and transform continuously as a company."
        imgSrc={futureDrivers}
      />
      <CTA />
      <Footer />
    </div>
  );
}
